html
  &.is_stop-scroll
    width: calc(100vw - var(--scroll-size_w, 0px))

[data-modal-init]
  &[data-modal-init="user"],
  &[data-modal-init="sort"],
  &[data-modal-init="share"]
    &.is_active
      --ybtn_zi: 1000
      --ybtn_pe: none

*:not(html):not(body):not(.scrollSize)
  [data-scroll-check]
    overflow-y: auto

    scrollbar-color: var(--cc1-1) var(--dd1)
    scrollbar-width: thin

    --scrollbar_track-width: 4px
    --scrollbar_track-color_left: var(--modal_r-bgc)
    --scrollbar_track-color_right: var(--modal_r-bgc)
    --scrollbar_track-color_center: var(--cc1-1)
    --scrollbar_trackColor: var(--modal_r-bgc)

    &::-webkit-scrollbar-track
      background: linear-gradient(to right, var(--scrollbar_track-color_left) 0%, var(--scrollbar_track-color_left) 30%, var(--scrollbar_track-color_center) 58%, var(--scrollbar_track-color_right) 61%, var(--scrollbar_track-color_right) 100%)
    &::-webkit-scrollbar
      width: var(--scrollbar_track-width)
    &::-webkit-scrollbar-thumb
      border-radius: var(--scrollbar_track-width)
    &.is_scroll
      @supports (scrollbar-width: thin)
        margin-right: calc(var(--modal_close_w) / 1.85 - var(--scrollbar_track-width))
        [data-scroll-inner]
          padding-right: calc(var(--modal_close_w) / 2 + var(--scrollbar_track-width) / 2)
  
      @supports not (scrollbar-width: thin)
        margin-right: calc(var(--modal_close_w) / 1.85 - var(--scrollbar_track-width) / 2)
        [data-scroll-inner]
          padding-right: calc(var(--modal_close_w) / 2 - var(--scrollbar_track-width) / 2)
        
.y-btn.is_fullvip
  width: max-content
  font-size: 16px
  line-height: 1
  padding: 5px 20px
  transition: transform .5s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 200ms, bottom 0s, right 0s, background 200ms !important
  color: #fff
  background-color: #ff581f
  border-color: #ff581f
  opacity: 1
  pointer-events: all
  transform: unset !important
  position: absolute
  bottom: 0
  right: calc(45px + 8px)
  font-weight: 600
  letter-spacing: .02em
  &:hover
    background-color: #d44f00
  &:not(:hover)
    opacity: 1

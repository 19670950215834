.y_list
  --list_row_gap: clamp(5px, 5px + (8 - 5) * (100vw - 731.3px) / (1435 - 731.3), 8px)
  --list_col_gap: var(--list_row_gap)
  --list_offset_row: 0 clamp(14px, 14px + (24 - 14) * (100vw - 731.3px) / (1435 - 731.3), 24px)
  --list_offset: var(--list_offset_row, 0) var(--list_offset_col, 0)
  --list_font_size: clamp(14px, 14px + (16 - 14) * (100vw - 731.3px) / (1435 - 731.3), 16px)
  display: inline-grid
  align-content: start
  align-items: baseline
  list-style-type: var(--list_style_type, initial)
  grid-template-columns: var(--list_template_columns, 100%)
  grid-gap: var(--list_row_gap, 0) var(--list_col_gap, 0)
  font-size: var(--list_font_size, inherit)
  &:not(:last-child):not(:only-child):not(:nth-last-child)
    margin: var(--list_offset_row)

  &_item
    display: contents


.y_list_item::before
  display: inline-flex
  grid-column: 1
  content: counter(var(--counter)) '.'
  color: var(--list_marker_color, currentColor)
  justify-self: var(--list_maeker_jself, center)
  font-size: 18px
  font-weight: inherit
  line-height: inherit
  font-style: inherit
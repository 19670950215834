:root {
  --font-fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --ff1-4: var(--font-fallback);
  --dd1: #fff;
  --dd2: #000;
  --dd3: #353e48;
  --cc1: #033792;
  --c1h: #82da02;
  --cc2: #ff6408;
  --ch2: #d44f00;
}

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

figure {
  margin: 0;
}

picture {
  display: contents;
}

.counter {
  counter-reset: var(--counter, item_counter);
}

.counter_item::before {
  display: flex;
  counter-increment: var(--counter, item_counter);
  content: counter(var(--counter, item_counter)) ".";
}

.y_list {
  --list_row_gap: clamp(5px, 5px + (8 - 5) * (100vw - 731.3px) / (1435 - 731.3), 8px);
  --list_col_gap: var(--list_row_gap);
  --list_offset_row: 0 clamp(14px, 14px + (24 - 14) * (100vw - 731.3px) / (1435 - 731.3), 24px);
  --list_offset: var(--list_offset_row, 0) var(--list_offset_col, 0);
  --list_font_size: clamp(14px, 14px + (16 - 14) * (100vw - 731.3px) / (1435 - 731.3), 16px);
  display: inline-grid;
  align-content: start;
  align-items: baseline;
  list-style-type: var(--list_style_type, initial);
  grid-template-columns: var(--list_template_columns, 100%);
  grid-gap: var(--list_row_gap, 0) var(--list_col_gap, 0);
  font-size: var(--list_font_size, inherit);
}
.y_list:not(:last-child):not(:only-child):not(:nth-last-child) {
  margin: var(--list_offset_row);
}
.y_list_item {
  display: contents;
}

.y_list_item::before {
  display: inline-flex;
  grid-column: 1;
  content: counter(var(--counter)) ".";
  color: var(--list_marker_color, currentColor);
  justify-self: var(--list_maeker_jself, center);
  font-size: 18px;
  font-weight: inherit;
  line-height: inherit;
  font-style: inherit;
}

.y_list.list_order {
  --counter: list_counter;
  --list_template_columns: minmax(20px, max-content) 1fr;
  --list_marker_content: var(--counter);
  --list_marker_color: var(--cc2);
  --list_maeker_jself: end;
}

.asteps__elms-instruction {
  padding-top: clamp(10px, 10px + 12.5 * (100vw - 731.3px) / 703.7, 22.5px);
  padding-bottom: clamp(10px, 10px + 12.5 * (100vw - 731.3px) / 703.7, 22.5px);
  padding-left: clamp(20px, 20px + 20 * (100vw - 731.3px) / 703.7, 40px);
  padding-right: clamp(20px, 20px + 20 * (100vw - 731.3px) / 703.7, 40px);
  --instruction_offset_row: calc((clamp(10px, 10px + (45 - 10) * (100vw - 731.3px) / (1435 - 731.3), 45px) - 10) / -2);
  margin: 0 0 var(--instruction_offset_row, 0) 0;
  background-color: #033792;
  border-radius: 4px 4px 0 0;
  display: flex;
  gap: 5px;
  align-items: center;
  align-content: start;
  color: var(--dd1);
  font-style: italic;
  font-size: clamp(12px, 12px + 4 * (100vw - 731.3px) / 703.7, 16px);
}
.asteps__instruction-icn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  width: clamp(22px, 22px + 6 * (100vw - 731.3px) / 703.7, 28px);
}
.asteps__instruction-content {
  padding-top: 3px;
  display: flex;
  align-items: baseline;
  gap: 5px;
}
.asteps__instruction-text, .asteps__instruction-btn {
  font-size: clamp(12px, 12px + 4 * (100vw - 731.3px) / 703.7, 16px);
  line-height: 22px;
}
.asteps__instruction-btn {
  padding: 1.5px 2px 1.5px 1.5px;
  margin: 0 0 2px 0 !important;
  color: #e9c84e;
  font-style: italic;
  position: relative;
}
.asteps__instruction-btn .y-btn__txt {
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: inherit;
  display: block;
}
.asteps__instruction-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: calc(100% + clamp(2px, 2px + 1 * (100vw - 731.3px) / 703.7, 3px));
  transform: scaleX(1.4) translate(-60%, -50%);
  border-bottom-style: solid;
  border-bottom-color: var(--dd1);
  border-bottom-width: clamp(2px, 2px + 1 * (100vw - 731.3px) / 703.7, 3px);
  background-color: transparent;
  transition: 300ms;
}
.asteps__instruction-btn:hover::before {
  transform: translate(-50%, -50%) scaleX(1.05);
  border-bottom-width: 2px;
}

.y-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 300ms 400ms;
  z-index: 999;
}
.y-overlay:not(.is_open) {
  pointer-events: none;
}
.y-overlay.is_open {
  opacity: 1;
  cursor: pointer;
  transition: 300ms;
}
.y-overlay.is_close {
  opacity: 0;
}

.y_modal {
  --modal_bgc: var(--dd1);
  --modal_r-bgc: rgba(var(--rd1),0);
  --modal_close_w: clamp(22px, 22px + (30 - 22) * (100vw - 370.3px) / (731 - 370.3), 30px);
  --modal_close_h: var(--modal_close_w);
  --modal_close_bdrs: clamp(4px, 4px + (8 - 4) * (100vw - 409.3px) / (805 - 409.3), 8px);
  --modal_offset_col: clamp(12px, 12px + (26 - 12) * (100vw - 662.3px) / (1435 - 662.3), 26px);
  --modal_offset_row: clamp(12px, 12px + (20 - 12) * (100vw - 662.3px) / (1435 - 662.3), 20px);
  --modal_pd: var(--modal_pdt, 16px) var(--modal_pdr, var(--modal_offset_col)) var(--modal_pdb, var(--modal_offset_row)) var(--modal_pdl, var(--modal_offset_col));
  --offset-y: clamp(10px, 10px + (15 - 10) * (100vw - 731.3px) / (1435 - 731.3), 15px);
  --modal_title_fz: clamp(16px, 16px + (18 - 16) * (100vw - 731.3px) / (1435 - 731.3), 18px);
  color: var(--dd3);
  position: var(--modal_pos, fixed);
  top: var(--modal_top, 48%);
  left: var(--modal_left, 50%);
  right: var(--modal_right, unset);
  transform: var(--modal_tf, translate(-50%, -50%) scale(0, 1));
  transform-origin: var(--modal_tfo, unset);
  --trs_set: transform 250ms 250ms, top 300ms, opacity 150ms 700ms;
  transition: var(--modal_trs, var(--trs_set));
  line-height: var(--modal_lh, 1);
  max-width: var(--modal_maxw, unset);
  min-width: var(--modal_minw, unset);
  min-height: var(--modal_minh, unset);
  max-height: var(--modal_maxh, 100vh);
  width: var(--modal_w, auto);
  --tftx: clamp(10px, 10px + (20 - 10) * (100vw - 370.3px) / (805 - 370.3), 20px);
  --modal_height_scale: calc(var(--viewportHeight, 0) - var(--tftx, 5px) * 2);
  height: var(--modal_h, var(--modal_height_scale));
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  display: flex;
}
.y_modal__inner {
  margin: auto 0;
  width: 100%;
  height: var(--modal_inner_h, auto);
  max-height: 100%;
  transition: var(--modal_inner_trs);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: var(--modal_pd);
  border-radius: var(--modal_bdrs, var(--modal_bdrs_tl, 8px) var(--modal_bdrs_tr, 8px) var(--modal_bdrs_br, 8px) var(--modal_bdrs_bl, 8px));
  background-color: var(--modal_bgc);
  box-shadow: var(--modal_boxs);
  pointer-events: auto;
}
.y_modal__btn-close {
  --zbtn_w: var(--modal_close_w);
  --zbtn_h: var(--modal_close_h);
  --zbtn_trs: .4s ease-in-out;
  --zbtn__txt_display: none;
  --zbtn_pos: relative;
  --zbtn_bdw: 1px;
  --zbtn_bdc: transparent;
  --zbtn_bdrs: var(--modal_close_bdrs);
  --zbtn_zi: 2;
}
.y_modal__btn-close::before, .y_modal__btn-close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 1px;
  border-radius: 2px;
  transition: 0.3s;
  background-color: var(--root_color, #000);
}
.y_modal__btn-close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.y_modal__btn-close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.y_modal__btn-close:hover {
  --zbtn_bdc: var(--cc2);
  --zbtn_trs: .3s;
  box-shadow: inset 0 0 2px calc(var(--modal_close_w) / 2) rgba(var(--rc2), 0.4), -1px 2px 4px rgba(0, 0, 0, 0.2);
}
.y_modal__btn-close:hover::before, .y_modal__btn-close:hover::after {
  height: 1px;
  transition: 0.25s 0.1s;
  box-shadow: 0 0 0 1px;
}
.y_modal__btn-close:hover::before {
  transform: translate(-50%, -50%) rotate(45deg) scale(0.6);
}
.y_modal__btn-close:hover::after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(0.6);
}
.y_modal__header, .y_modal__footer {
  display: grid;
  align-items: center;
}
.y_modal__header {
  grid-gap: 5px;
  grid-template-columns: 1fr var(--modal_close_w);
  padding-bottom: var(--offset-y);
}
.y_modal__header > *:nth-child(n+3) {
  grid-column: 1/-1;
}
.y_modal__footer {
  grid-gap: 10px;
  grid-template-columns: 100%;
  padding-top: var(--offset-y);
}
.y_modal__title {
  align-items: baseline;
  display: flex;
  padding-left: 10px;
  gap: 5px;
  line-height: 1;
  font-family: var(--modal_title_ff, var(--ff1-7));
  font-weight: 700;
  font-size: var(--modal_title_fz, 18px);
}
.y_modal__main {
  flex-grow: 1;
  max-height: 100%;
}
.y_modal__main:only-child {
  padding: 8px;
}
.y_modal__main-inner {
  --main-inner_row-gap: 20px;
  --main-inner_col-gap: 8px;
  line-height: normal;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: var(--main-inner_row-gap, 0) var(--main-inner_col-gap, 0);
  opacity: 0;
  transition: 250ms, padding 0s;
  height: 100%;
}
.y_modal__btn {
  --zbtn_minh: 32px;
  --zbtn_pd: 5px 16px;
  --zbtn_bdrs: 6px;
  --zbtn_gap: 5px;
}
@media screen and (min-width: 1435px) {
  .y_modal__btn {
    --zbtn_minw: 91px;
  }
}
.y_modal__empty {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.y_modal__empty-inner {
  text-align: center;
}
.y_modal__empty-title {
  font-family: var(--ff1-7);
  font-size: 16px;
  line-height: 1.5;
  color: var(--dd3);
}
.y_modal__empty-text {
  font-size: 14px;
  line-height: 1.7;
  color: var(--dd4-3);
}
.y_modal.is_open {
  opacity: 1;
  --modal_top: 50%;
  --modal_tf: translate(-50%, -50%) scale(1, 1);
  --modal_trs: 250ms, top 300ms 250ms, transform 250ms 50ms;
}
.y_modal.is_open .y_modal__main-inner {
  opacity: 1;
  transition: 250ms 400ms, padding 0s;
}

html.is_stop-scroll {
  width: calc(100vw - var(--scroll-size_w, 0px));
}

[data-modal-init][data-modal-init=user].is_active, [data-modal-init][data-modal-init=sort].is_active, [data-modal-init][data-modal-init=share].is_active {
  --ybtn_zi: 1000;
  --ybtn_pe: none;
}

*:not(html):not(body):not(.scrollSize) [data-scroll-check] {
  overflow-y: auto;
  scrollbar-color: var(--cc1-1) var(--dd1);
  scrollbar-width: thin;
  --scrollbar_track-width: 4px;
  --scrollbar_track-color_left: var(--modal_r-bgc);
  --scrollbar_track-color_right: var(--modal_r-bgc);
  --scrollbar_track-color_center: var(--cc1-1);
  --scrollbar_trackColor: var(--modal_r-bgc);
}
*:not(html):not(body):not(.scrollSize) [data-scroll-check]::-webkit-scrollbar-track {
  background: linear-gradient(to right, var(--scrollbar_track-color_left) 0%, var(--scrollbar_track-color_left) 30%, var(--scrollbar_track-color_center) 58%, var(--scrollbar_track-color_right) 61%, var(--scrollbar_track-color_right) 100%);
}
*:not(html):not(body):not(.scrollSize) [data-scroll-check]::-webkit-scrollbar {
  width: var(--scrollbar_track-width);
}
*:not(html):not(body):not(.scrollSize) [data-scroll-check]::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar_track-width);
}
@supports (scrollbar-width: thin) {
  *:not(html):not(body):not(.scrollSize) [data-scroll-check].is_scroll {
    margin-right: calc(var(--modal_close_w) / 1.85 - var(--scrollbar_track-width));
  }
  *:not(html):not(body):not(.scrollSize) [data-scroll-check].is_scroll [data-scroll-inner] {
    padding-right: calc(var(--modal_close_w) / 2 + var(--scrollbar_track-width) / 2);
  }
}
@supports not (scrollbar-width: thin) {
  *:not(html):not(body):not(.scrollSize) [data-scroll-check].is_scroll {
    margin-right: calc(var(--modal_close_w) / 1.85 - var(--scrollbar_track-width) / 2);
  }
  *:not(html):not(body):not(.scrollSize) [data-scroll-check].is_scroll [data-scroll-inner] {
    padding-right: calc(var(--modal_close_w) / 2 - var(--scrollbar_track-width) / 2);
  }
}

.y-btn.is_fullvip {
  width: max-content;
  font-size: 16px;
  line-height: 1;
  padding: 5px 20px;
  transition: transform 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 200ms, bottom 0s, right 0s, background 200ms !important;
  color: #fff;
  background-color: #ff581f;
  border-color: #ff581f;
  opacity: 1;
  pointer-events: all;
  transform: unset !important;
  position: absolute;
  bottom: 0;
  right: 53px;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.y-btn.is_fullvip:hover {
  background-color: #d44f00;
}
.y-btn.is_fullvip:not(:hover) {
  opacity: 1;
}
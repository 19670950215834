.y_modal
  --modal_bgc: var(--dd1)
  --modal_r-bgc: rgba(var(--rd1),0)
  --modal_close_w: clamp(22px, 22px + (30 - 22) * (100vw - 370.3px) / (731 - 370.3), 30px)
  //--modal_close_w: 22px
  //+rsp(--modal_close_w, $mm2 + .1, $mm6, 22px, 30px)

  --modal_close_h: var(--modal_close_w)
  --modal_close_bdrs: clamp(4px, 4px + (8 - 4) * (100vw - 409.3px) / (805 - 409.3), 8px)
  //--modal_close_bdrs: 4px
  //+rsp(--modal_close_bdrs, $mm3 + .1, $mm8, 4px,  8px)

  --modal_offset_col: clamp(12px, 12px + (26 - 12) * (100vw - 662.3px) / (1435 - 662.3), 26px)
  --modal_offset_row: clamp(12px, 12px + (20 - 12) * (100vw - 662.3px) / (1435 - 662.3), 20px)
  --modal_pd: var(--modal_pdt, 16px) var(--modal_pdr, var(--modal_offset_col)) var(--modal_pdb, var(--modal_offset_row)) var(--modal_pdl, var(--modal_offset_col))
  --offset-y: clamp(10px, 10px + (15 - 10) * (100vw - 731.3px) / (1435 - 731.3), 15px)
  // --offset-y: 10px
  //+rsp(--offset-y, $mm6 + .1, $mm14, 10px,  15px)

  --modal_title_fz: clamp(16px, 16px + (18 - 16) * (100vw - 731.3px) / (1435 - 731.3), 18px)
  // --modal_title_fz: 16px
  //+rsp(--modal_title_fz, $mm6 + .1, $mm14, 16px,  18px)
  
  color: var(--dd3)
  position: var(--modal_pos, fixed)
  top: var(--modal_top,48%)
  left: var(--modal_left,50%)
  right: var(--modal_right,unset)
  transform: var(--modal_tf, translate(-50%, -50%) scale(0, 1))
  transform-origin: var(--modal_tfo, unset)
  --trs_set: transform 250ms 250ms, top 300ms, opacity 150ms 700ms
  transition: var(--modal_trs, var(--trs_set))
  line-height: var(--modal_lh, 1)

  max-width: var(--modal_maxw, unset)
  min-width: var(--modal_minw, unset)
  min-height: var(--modal_minh, unset)
  max-height: var(--modal_maxh, 100vh)

  width: var(--modal_w, auto)
  --tftx: clamp(10px, 10px + (20 - 10) * (100vw - 370.3px) / (805 - 370.3), 20px)
  --modal_height_scale: calc(var(--viewportHeight, 0) - var(--tftx, 5px) * 2)
  // --tftx: 5px
  //+rsp(--tftx, $mm2 + .1, $mm8, 10px, 20px)
  height: var(--modal_h, var(--modal_height_scale))
  z-index: 1000
  opacity: 0
  pointer-events: none
  display: flex
  &__inner
    margin: auto 0
    width: 100%
    height: var(--modal_inner_h, auto)
    max-height: 100%

    transition: var(--modal_inner_trs)
    display: flex
    flex-direction: column
    position: relative

    padding: var(--modal_pd)

    border-radius: var(--modal_bdrs, var(--modal_bdrs_tl,8px) var(--modal_bdrs_tr,8px) var(--modal_bdrs_br,8px) var(--modal_bdrs_bl,8px))
    background-color: var(--modal_bgc)
    box-shadow: var(--modal_boxs)
    pointer-events: auto

  &__btn-close
    --zbtn_w: var(--modal_close_w)
    --zbtn_h: var(--modal_close_h)
    --zbtn_trs: .4s ease-in-out
    --zbtn__txt_display: none
    --zbtn_pos: relative
    --zbtn_bdw: 1px
    --zbtn_bdc: transparent
    --zbtn_bdrs: var(--modal_close_bdrs)
    --zbtn_zi: 2

    &::before,
    &::after
      content: ''
      position: absolute
      top: 50%
      left: 50%
      width: 100%
      height: 1px
      border-radius: 2px
      transition: .3s
      background-color: var(--root_color, #000)

    &::before
      transform: translate(-50%,-50%) rotate(45deg)
    &::after
      transform: translate(-50%,-50%) rotate(-45deg)

    &:hover
      --zbtn_bdc: var(--cc2)
      --zbtn_trs: .3s
      box-shadow: inset 0 0 2px calc(var(--modal_close_w) / 2) rgba(var(--rc2), .4), -1px 2px 4px rgba(#000, .2)

      &::before,
      &::after
        height: 1px
        transition: .25s .1s
        box-shadow: 0 0 0 1px
      &::before
        transform: translate(-50%,-50%) rotate(45deg) scale(.6)
      &::after
        transform: translate(-50%,-50%) rotate(-45deg) scale(.6)

  &__header,
  &__footer

    display: grid
    align-items: center
  &__header
    grid-gap: 5px
    grid-template-columns: 1fr var(--modal_close_w)
    padding-bottom: var(--offset-y)

  &__header
    >*:nth-child(n+3)
      grid-column: 1/-1

  &__footer
    grid-gap: 10px
    grid-template-columns: 100%
    padding-top: var(--offset-y)

  &__title
    align-items: baseline
    display: flex
    padding-left: 10px
    gap: 5px
    line-height: 1
    font-family: var(--modal_title_ff, var(--ff1-7))
    font-weight: 700
    font-size: var(--modal_title_fz, 18px)
    

  &__main
    flex-grow: 1
    max-height: 100%

  &__main:only-child
    padding: 8px

  &__main-inner
    --main-inner_row-gap: 20px
    --main-inner_col-gap: 8px

    line-height: normal
    display: grid
    grid-template-columns: 100%
    grid-gap: var(--main-inner_row-gap, 0) var(--main-inner_col-gap, 0)
    opacity: 0
    transition: 250ms, padding 0s
    height: 100%

  &__btn
    --zbtn_minh: 32px
    //+rsp(--btn_minh, $mm6 + .1, $mm14, 32px,  40px)
    +mm14
      --zbtn_minw: 91px
    --zbtn_pd: 5px 16px
    --zbtn_bdrs: 6px
    //+rsp(--btn_bdrs, $mm6 + .1, $mm14, 6px,  12px)
    --zbtn_gap: 5px
    //+rsp(--btn_gap, $mm6 + .1, $mm14, 5px,  10px)

  &__empty
    height: 80%
    display: flex
    align-items: center
    justify-content: center
  &__empty-inner
    text-align: center
  &__empty-title
    font-family: var(--ff1-7)
    font-size: 16px
    line-height: 1.5
    color: var(--dd3)


  &__empty-text
    font-size: 14px
    line-height: 1.7
    color: var(--dd4-3)

  &.is_open
    opacity: 1
    --modal_top: 50%
    --modal_tf: translate(-50%, -50%) scale(1, 1)
    --modal_trs: 250ms, top 300ms 250ms, transform 250ms 50ms

  &.is_open &
    &__main-inner
      opacity: 1
      transition: 250ms 400ms, padding 0s

//%modal-arrow
//    position: absolute
//    bottom: var(--arrow_bot, 50%)
//    left: var(--arrow_left,50%)
//    transform: var(--arrow_tf, translateX(-50%))
//    transition: var(--arrow_trs,300ms)

//%modal-arrow-top
//  &::before
    //+tr('top', var(--tr_w,20px), var(--tr_h,10px), var(--modal_bgc))

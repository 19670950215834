.y-overlay
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  background-color: rgba(#000,.6)
  transition: 300ms 400ms
  z-index: 999
  &:not(.is_open)
    pointer-events: none
  &.is_open
    opacity: 1
    cursor: pointer
    transition: 300ms
  &.is_close
    opacity: 0
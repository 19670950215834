\:root
  // fonts -init-
  --font-fallback: BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
  --ff1-4: var(--font-fallback)
  // fonts -end-
  
  // decor-colors -init-
  --dd1: #fff
  --dd2: #000
  --dd3: #353e48
  // decor-colors -end-
  
  // main-colors -init-
  --cc1: #033792
  --c1h: #82da02
  
  --cc2: #ff6408
  --ch2: #d44f00
  // main-colors -end-

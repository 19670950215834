.asteps
  &__elms-instruction
    padding:
      top: clamp(10px, 10px + (22.5 - 10) * (100vw - 731.3px) / (1435 - 731.3), 22.5px)
      bottom: clamp(10px, 10px + (22.5 - 10) * (100vw - 731.3px) / (1435 - 731.3), 22.5px)
      left: clamp(20px, 20px + (40 - 20) * (100vw - 731.3px) / (1435 - 731.3), 40px)
      right: clamp(20px, 20px + (40 - 20) * (100vw - 731.3px) / (1435 - 731.3), 40px)
      
    --instruction_offset_row:  calc((clamp(10px, 10px + (45 - 10) * (100vw - 731.3px) / (1435 - 731.3), 45px) - 10) / -2)
    margin: 0 0 var(--instruction_offset_row, 0) 0
    background-color: #033792
    border-radius: 4px 4px 0 0
    display: flex
    gap: 5px
    align-items: center
    align-content: start
    color: var(--dd1)
    font-style: italic
    font-size: clamp(12px, 12px + (16 - 12) * (100vw - 731.3px) / (1435 - 731.3), 16px)
  
  &__instruction-icn
    display: flex
    justify-content: center
    align-items: center
    margin-right: 3px
    width: clamp(22px, 22px + (28 - 22) * (100vw - 731.3px) / (1435 - 731.3), 28px)
  &__instruction-content
    padding-top: 3px
    display: flex
    align-items: baseline
    gap: 5px
  &__instruction-text,
  &__instruction-btn
    font-size: clamp(12px, 12px + (16 - 12) * (100vw - 731.3px) / (1435 - 731.3), 16px)
    line-height: 22px
    // transform: translateY((20px - 28))
  &__instruction-btn
    
    padding: 1.5px 2px 1.5px 1.5px
    margin: 0 0 2px 0 !important
    color: #e9c84e
    font-style: italic
    position: relative
    .y-btn__txt
      font-weight: 600
      letter-spacing: 0.05em
      font-size: inherit
      display: block
    &::before
      content: ''
      display: block
      position: absolute
      
      top: 50%
      left: 50%
      width: 100%
      height: calc(100% + clamp(2px, 2px + (3 - 2) * (100vw - 731.3px) / (1435 - 731.3), 3px))
      transform: scaleX(1.4) translate(-60%, -50%)
      border-bottom-style: solid
      border-bottom-color: var(--dd1)
      border-bottom-width: clamp(2px, 2px + (3 - 2) * (100vw - 731.3px) / (1435 - 731.3), 3px)
      background-color: transparent
      transition: 300ms
    &:hover
      &::before
        transform: translate(-50%, -50%) scaleX(1.05)
        border-bottom-width: 2px
  // &__instruction-modal
